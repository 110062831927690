<template>
  <div class="terms-page">
    <h2>Scope</h2>
    <p>This document provides you with information about how HSBC, its authorised agency CSM Sport and Entertainment LLP ('CSM’), and its application developer Ember Technology Ltd (‘Ember’) is handling, or is intending to handle, your personal information collected via the Get Active Hub Application (‘the Application’). This Application is managed by Ember in partnership with HSBC Holdings plc.</p>

    <h2>About Us</h2>
    <p>HSBC, CSM and Ember (together, ‘the Group’) are committed to protecting and respecting your privacy and complying with the principles of applicable data protection laws.  This notice sets out the basis on which any personal data we collect from you, or that you provide to us, will be used and managed by us. The data controller of the personal data referred to in this policy is HSBC Holdings plc.</p>

    <h2>Collection of Personal Data</h2>
    <p>The Group may collect and/or create or otherwise obtain and process the following data about you:</p>
    <ul>
      <li>Information about you that you provide while using the Application.</li>
    </ul>
    <p>This includes information provided at the time of registering to use the Application, subscribing to our communications, posting material, logging activity and taking part in challenges.</p>
    <ul>
      <li>Information from your social media accounts (e.g. Facebook and Strava) where you have given us permission to use it. For example, data relating to activities you have recently completed on Strava where you have linked your account with the Application.</li>
      <li>We may also ask you for information when you report a problem or make a complaint and, if you contact us, we may keep a record of that correspondence.</li>
      <li>We may also ask you to complete optional surveys that we use for research purposes and to provide you with a more relevant customer experience.</li>
      <li>Details of when you digitally interact with the Group via our websites and other digital channels and the resources that you access which may include the use of cookies (subject to our Cookie policy).</li>
      <li>Information about emails and other communications we have sent to you and your interaction with them.</li>
      <li>Information from third parties where you consent to those other organisations sharing information they hold on you with us, or where those other organisations otherwise lawfully share your information with us.</li>
    </ul>

    <h2>Uses Made of Your Information and the Basis of Processing</h2>
    <p>The Group will use your personal information to:</p>
    <ul>
      <li>Ensure that content is presented in the most effective manner;</li>
      <li>Authenticate you when you register;</li>
      <li>Carry out our obligations arising from any contracts entered into between you and the Group;</li>
      <li>Provide you with information, products or services that you request from the Group or which we feel may interest you, where we are legally entitled to do so;</li>
      <li>Enable people to join events and activities and communicate with each other via the Application as part of that activity;</li>
      <li>Allow you to participate in interactive features of the Application, when you choose to do so;</li>
      <li>Notify you about changes to our service;; or</li>
      <li>To segment your personal data to make sure that you only receive information that is relevant to you.</li>
    </ul>
    <p>The Group will not use any of the personal information we collect from you to make automated business decisions.</p>
    <p>The legal basis on which we collect and process the personal data described above depends on the personal information concerned and the specific context in which we collect it. However, we will only use your personal information where we:</p><p>
      <ul>
        <li>Have your consent to do so;</li>
        <li>Need the personal data to perform a contract with you;</li>
        <li>Need to process your personal information for our legitimate interests and only where our legitimate interests are not overridden by your data protection interests or fundamental rights and freedoms;</li>
        <li>Have a legal obligation to collect personal information from you; or</li>
        <li>Need the personal information protect your vital interests or those of another person.</li>
      </ul>
    </p>
    <p>If we ask you to provide personal information to comply with a legal requirement or to perform a contract with you, we will make this clear at the relevant time, and advise you whether the provision of your personal information is mandatory or not (as well as the possible consequences if you do not provide your personal information).</p>

    <h2>Information Security</h2>
    <p>The Group will take all steps reasonably necessary including policies, procedures and security features to ensure that your data is treated securely and protected from unauthorised and unlawful access and/or use, and in accordance with this notice.  Unfortunately, the transmission of information via the internet is not completely secure and, although we will do our best to protect your personal data transmitted to us via the internet, we cannot guarantee the security of your data transmitted to our website from your device. Any transmission is at your own risk.</p>
    <p>Where we have given you (or where you have chosen) a password which enables you to access certain parts of the website, you are responsible for keeping this password confidential. We ask you not to share such a password with anyone.</p>
    <p>Where any payments are being collected on our behalf, we require our payment providers to be compliant with the Payment Card Industry’s Data Security standards (PCI-DSS).</p>

    <h2>Recipients of Personal Data</h2>
    <p>We will share information about you with some of our suppliers who process data on our behalf to help us to provide services to you. We undertake this data sharing on the basis of our legitimate interests.</p>
    <p>We will also share your personal information in the form of Application usage and performance reports with HSBC UK and other service providers who work in partnership with us to deliver the Application.</p>

    <h2>Categories of Organisation and Purpose</h2>
    <ul>
      <li><strong>Creative agencies</strong> – to provide content and reporting services for the Application</li>
      <li><strong>Web developers</strong> – to develop and provide technical support for the Application</li>
      <li><strong>Database hosting companies</strong> - to host CSM digital platforms and associated customer databases to enable customers to log in and interact with the Application</li>
      <li><strong>Email broadcasting companies</strong> - to send emails to our customers</li>
      <li><strong>SMS broadcasting companies</strong> - to send SMS and text messages to our customers</li>
      <li><strong>Mailing houses</strong> - To send products and benefits to our customers that are not directly produced by CSM (e.g. publications)</li>
      <li><strong>Market research companies</strong> - to undertake research of our own customers</li>
      <li><strong>Social media companies (e.g. Facebook/Strava)</strong> - to verify your identity when you use 'register with' functions and to provide you with relevant social media posts</li>
    </ul>
  
    <h2>International Transfer of Personal Data</h2>
    <p>We do not envisage transferring any information about or relating to individuals to anyone who is located outside of the European Economic Area.</p>
    <p>However, on some occasions, the information we collect may be transferred to organisations who may store and use such data at premises in other countries. Where we allow an organisation to process your personal information outside of the European Economic Area, we will ensure that we create and maintain appropriate application safeguards with those organisations so that your personal information is subject to the same standards and protections as when we are processing your personal information inside the European Economic Area.</p>

    <h2>Data Retention Period</h2>
    <p>We will hold information about you in our data systems only for as long as we need it for the purpose for which we collected it. Personal data linked to the processing of insurance claims, subject access requests, disputes, safeguarding investigations, disciplinary or police matters will only be kept for as long as it necessary for those purposes, as each is applicable.</p>

    <h2>Deleting your Account</h2>
    <p>If you wish to delete your account please contact <a href="mailto:getactivehub@hsbc.com">getactivehub@hsbc.com</a> and this will be actioned within 30 days.</p>

    <h2>The Data Subject’s Rights</h2>
    <p>Data protection laws grant you, as a Data Subject, certain ‘information rights’, which are summarised below:</p>
    <ul>
      <li><strong>Right of access</strong> - You have the right to obtain a copy of information we hold about you</li>
      <li><strong>Right of rectification or erasure</strong> - If you feel that any data that we hold about you is inaccurate, you have the right to ask us to correct or rectify it. You also have a right to ask us to erase information about you where you can demonstrate that the data we hold is no longer needed by us, or if you withdraw the consent upon which our processing is based, or if you feel that we are unlawfully processing your data. Please note that we may be entitled to retain your personal data despite your request, for example if we are under a separate legal obligation to retain it. Your right of rectification and erasure extends to anyone we have disclosed your personal information to and we will take all reasonable steps to inform those with whom we have shared their data about your request for erasure.</li>
      <li><strong>Right to restriction of processing</strong> - You have a right to request that we refrain from processing your data where you contest its accuracy, or the processing is unlawful and you have opposed its erasure, or where we do not need to hold your data any longer but you need us to in order to establish, exercise or defend any legal claims, or we are in dispute about the legality of our processing your personal data.</li>
      <li><strong>Right to Portability</strong> - You have a right to receive any personal data that you have provided to us in order to transfer it onto another data controller where the processing is based on consent and is carried out by automated means. This is called a data portability request.</li>
      <li><strong>Right to Object</strong> - You have a right to object to our processing your personal data where the basis of the processing is our legitimate interests including but not limited to direct marketing and profiling.</li>
      <li><strong>Right to Withdraw Consent</strong> - You have the right to withdraw your consent for the processing of your personal data where the processing is based on consent.</li>
      <li><strong>Right of Complaint</strong> - You also have the right to lodge a complaint about any aspect of how we are handling your data with the UK Information Commissioner’s Office, which can be contacted at  <a href="www.ico.org.uk">www.ico.org.uk</a>.</li>
      <li><strong>Right to Opt-out of Marketing Communications</strong> - You have the right to opt-out of marketing communications we send to you at any time. You can exercise this right by clicking on the “unsubscribe” or “opt-out” link in the marketing emails we send you. To opt out of other forms of marketing (such as postal marketing or telemarketing), then please contact us using the contact details provided below.</li>
    </ul>

    <h2>Changes to our Privacy Policy</h2>
    <p>Any changes we may make to our Privacy Notice in the future will be posted on this page and, where appropriate, notified to you by date-stamped communication.</p>

    <h2>How to Contact Us</h2>
    <p>If you wish to contact us about your personal data or exercise any of the rights described above please contact:</p>

    <p><u>HSBC</u></p>
    <p>If you’d like further information on anything we’ve said in this notice, the event, or to contact our Data Protection Officer where applicable, please contact us using the details below.</p>
    <p>HSBC UK Bank plc Registered in England number 9928412. Registered Office: 1 Centenary Square, Birmingham, B1 1HQ. Authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority. Our firm registration number is 765112. <a href="https://www.hsbc.co.uk/privacy-notice/">https://www.hsbc.co.uk/privacy-notice/</a></p>

    <p><u>CSM</u></p>
    <ul>
      <li>Email: <a href="mailto:hsbcpartnerships@csm.com">hsbcpartnerships@csm.com</a></li>
      <li>Name: Henry Marks</li>
      <li>Address: 10a Greencoat Place, London, SW1P 1PH</li>
      <li>Copy to: <a href="mailto:csm.legal@csm.com">csm.legal@csm.com</a></li>
    </ul>

    <p><u>Ember</u></p>
    <ul>
      <li>Email: <a href="mailto:support@ember.ltd">support@ember.ltd</a></li>
      <li>Name: Steven Wexelstein</li>
      <li>Address: 8 Pentland House, Saltire Centre, Glenrothes, Fife, KY6 2AH</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicyPage',
}
</script>
